<template>
  <div class="editor-toolbar">
    <div ref="editor" style="text-align:left;width:100%;"></div>
  </div>
</template>

<script>
import E from "wangeditor"; 
const smallmenu = [
  "head", //标题
  "bold", //粗体
  "fontSize", //字号
  "fontName", //字体
  "italic", //斜体
  "undeline", //下划线
  "strikeThrough", //删除线
  "foreColor", //文字颜色
  "backColor", //背景颜色
  // 'link',//插入链接
  // 'list',//列表
  "justify", //对齐方式
  // 'quote',//引用
  "emoticon", //表情
  // 'image',//插入图片
  // 'table',//表格
  // 'video',//插入视频
  // 'code',//插入代码
  "undo", //撤销
  "redo" // 重复
];
export default {
  name: "editor",
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    initialVal: {
      type: String,
      default: ""
    },
    small: {
      type: Boolean,
      default: false
    },
		keyName:{
			type:String,
			default:''
		},
  },
  data() {
    return {
      editor: null,
      editorContent: ""
    };
  },
  methods: {
    getContent: function() {
      alert(this.editorContent);
    },  removeWordXml(text){
            var html = text;
            html = html.replace(/<\/?SPANYES[^>]*>/gi, "");//  Remove  all  SPAN  tags
            // html = html.replace(/<(\w[^>]*)  class=([^|>]*)([^>]*)/gi, "<$1$3");  //  Remove  Class  attributes
            // html = html.replace(/<(\w[^>]*)  style="([^"]*)"([^>]*)/gi, "<$1$3");  //  Remove  Style  attributes
            html = html.replace(/<(\w[^>]*)  lang=([^|>]*)([^>]*)/gi, "<$1$3");//  Remove  Lang  attributes
            html = html.replace(/<\\?\?xml[^>]*>/gi, "");//  Remove  XML  elements  and  declarations
            html = html.replace(/<\/?\w+:[^>]*>/gi, "");//  Remove  Tags  with  XML  namespace  declarations:  <o:p></o:p>
            html = html.replace(/&nbsp;/, "");//  Replace  the  &nbsp;
            html = html.replace(/\n(\n)*( )*(\n)*\n/gi, '\n');
            //  Transform  <P>  to  <DIV>
            // var  re  =  new  RegExp("(<P)([^>]*>.*?)(<//P>)","gi")  ;            //  Different  because  of  a  IE  5.0  error
            //        html = html.replace(re, "<div$2</div>");
         
          return html;
    }
  },
  mounted() {
    let _this = this;
    var editor = new E(this.$refs.editor);
    if (_this.small) editor.config.menus = smallmenu;

    //配置
    editor.config.onchange = html => {
      try{
          html = html.replace(/http:\/\/traveladmin.fengshiyun.cn/,'');//去掉所有域名 处理图片中含有域名情况

      }catch(e){}
	  // var imtes={
		 //  keyName:this.keyName,
		 //  html:html
	  // }
      _this.editorContent = html;
	  _this.$emit("change", this.keyName,html);
      // _this.$emit("change", imtes);
    };
    editor.config.zIndex = 1;
    //图片上传地址
    editor.config.uploadImgServer = "/antis-service/api/upload/multiple";
    // 将图片大小限制为 5M
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
    // 限制一次最多上传 5 张图片
    editor.config.uploadImgMaxLength = 1;
    //post data
    editor.config.uploadImgParams = {
      // 如果版本 <=v3.1.0 ，属性值会自动进行 encode ，此处无需 encode
      // 如果版本 >=v3.1.1 ，属性值不会自动 encode ，如有需要自己手动 encode
      type: "editor"
    };
    //自定义formdata名称
    editor.config.uploadFileName = "file";
    //headers
    editor.config.uploadImgHeaders = {
      Authorization: '-1'
    };
    editor.config.customAlert = function(info) {
      // info 是需要提示的内容
      _this.$message({
        message: info,
        type: "warning"
      });
    };
    editor.config.uploadImgHooks = {
      customInsert: function(insertImg, result, editor) { 
        // 图片上传并返回结果，自定义插入图片的事件（而不是编辑器自动插入图片！！！）
        // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果

        // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
        for (let url of result.data) {
          insertImg(url);
        }
      }
    };
 
    editor.create();
    if (this.placeholder) {
      editor.txt.html(this.placeholder);
    }
    if (this.value) {
      editor.txt.html(this.value);
    }
    if (this.initialVal) {
      // console.log('editor initilVal',this.initialVal);
      editor.txt.html(this.initialVal);
    }
    this.editor = editor;
 
  },
  watch: {
    initialVal: function() { 
     try  {
        if (this.initialVal) {  
        this.editor.txt.html(this.initialVal);
      }  
     }catch(e){}
    } 
  }
};
</script>

<style scoped>
 
</style>
